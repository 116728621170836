<template>
  <v-container grid-list-md text-xs-center class="fundo" fluid>
    <v-layout row wrap>
      <!-- LOGOTIPO -->
      <v-flex xl12 lg12 md12 sm12 xs12 class="cabecalho">
        <img
          src="../../assets/logo.svg"
          width="200px"
          alt="Enercred - Energia Limpa e Barata"
        />
      </v-flex>
      <v-flex xl6 lg6 md6 sm12 xs12 style="background: #FFF">
        <v-container grid-list-md text-xs-center>
          <v-layout align-center justify-space-around row fill-height>
            <v-form ref="form" v-model="valid">
              <v-flex xs12>
                Para atender as normas da Aneel precisamos de mais alguns dados <a style="font-weight: 900">do titular da conta</a>.
              </v-flex>
              <!-- <v-layout fluid>
                <v-flex xs12>
                  <v-text-field
                    v-model="profissao"
                    label="Profissão"
                    name="profissao"
                    :rules="profissaoRules"
                    required
                    outline
                  ></v-text-field>
                </v-flex>
              </v-layout> -->
              <!-- identidade -->
              <v-layout fluid>
                <v-flex xs8>
                  <v-text-field
                    v-model="identidade"
                    label="Documento de Identidade"
                    name="identidade"
                    :rules="identidadeRules"
                    required
                    outline
                  ></v-text-field>
                </v-flex>
                <v-flex xs4>
                  <v-text-field
                    v-model="orgao_exp"
                    label="Orgão Expeditor"
                    name="orgao_exp"
                    :rules="orgao_expRules"
                    required
                    outline
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <!-- data de nascimento -->
              <v-flex xs12 v-if="!isDate_of_birth">
                <v-text-field
                  v-model="date_of_birth"
                  label="Data de Nascimento"
                  mask="##/##/####"
                  name="date_of_birth"
                  :rules="[v => !!v || 'Informe a data de nascimento do titular da conta']"
                  required
                  outline
                ></v-text-field>
              </v-flex>
              <!-- genero -->
              <v-flex xs12 v-if="!isGender">
                <header style="padding-bottom: 4px;  text-align: left">Sexo do titular da conta?</header>
                <v-radio-group
                  v-model="gender"
                  :rules="[v => !!v || 'Informe o sexo do titular da conta']"
                  row
                >
                  <v-radio style="margin-right: 8px" label="Masculino" value="M"></v-radio>
                  <v-radio label="Feminino" value="F"></v-radio>
                </v-radio-group>
              </v-flex>
              <!-- Estado civil -->
              <!-- <v-flex xs12>
                <header
                  style="padding-bottom: 4px; padding-left: 3px; text-align: left"
                >Estado civil</header>
                <v-radio-group
                  v-model="estadoCivil"
                  row
                  @click="deselecionaRegimeDeBens()"
                  :rules="[v => !!v || 'Por favor, informe o estado civil do titular da conta']"
                >
                  <v-radio
                    v-for="(ec, index) in estadosCivis"
                    style="margin-right: 3px"
                    :key="index"
                    :label="ec"
                    :value="ec"
                  ></v-radio>
                </v-radio-group>
              </v-flex> -->
              <!-- Comunhao de bens -->
              <!-- <v-flex xs12>
                <header
                  v-if="estadoCivil === 'Casado(a)'"
                  style="text-align: left; padding-bottom: 4px; padding-left: 3px"
                >Qual Regime de Bens é casado(a). Comunhão:</header>
                <v-radio-group 
                  v-model="comunhaoDeBens"
                  row
                  v-if="estadoCivil === 'Casado(a)'"
                >
                  <v-radio
                    v-for="(rdb, index) in regimeDeBens"
                    style="padding-right: 4px"
                    :key="index"
                    :label="rdb"
                    :value="rdb"
                  ></v-radio>
                </v-radio-group>
              </v-flex> -->
              <v-flex xs12>
                <!-- Titular ou familiar -->
                <header style="text-align: left">Você é o titular da conta de luz?</header>
                <v-radio-group
                  v-model="titular"
                  :rules="[
                    v => !!v || 'Por favor, informe se é o titular da conta!'
                  ]"
                  required
                >
                  <v-radio :key="1" :label="`Sou titular da Conta`" value="Sim"></v-radio>
                  <!-- <v-radio :key="2" :label="`Sou familiar do titular`" value="Familiar"></v-radio> -->
                </v-radio-group>
              </v-flex>
              <!-- Forma de Pagamento -->
              <v-flex xs12>
                <header style="text-align: left">Forma de Pagamento</header>
                <v-radio-group
                  v-model="payment_type"
                  :rules="[
                    v => !!v || 'Por favor, informe como deseja pagar sua energia limpa!'
                  ]"
                  style="margin-bottom: -18px"
                >
                  <!-- <v-radio :key="3" label="Pix" value="pix"></v-radio>
                  <v-radio :key="1" label="Boleto" value="boleto"></v-radio> -->
                  <v-radio :key="2" label="Cartão de Crédito" value="credit_card"></v-radio>
                </v-radio-group>
                <!-- <v-flex xs12 class="text-xs-left" style="padding-top: 0px;"><small>Fique tranquilo, você só paga após receber créditos em sua conta de energia</small></v-flex> -->
                <!-- <v-flex xs12 class="text-xs-left" style="padding-top: 0px;"><small>Com os dados informados vamos realizar a validação de seus dados junto a concessionária de energia, esse processo leva em média 30 minutos, mas pode demorar até 12 horas.</small></v-flex>
                <v-flex xs12 class="text-xs-left" style="padding-top: 0px;"><small>Após isso você receberá uma mensagem com o status do processo e se tudo estiver ok, receberá em seu email o contrato para assinatura.</small></v-flex> -->
              </v-flex>

              <!-- Termo de Compromisso -->
              <!-- <v-flex xs12 style="text-align: left; padding: 0 0 0 7px; margin: 0">
                <v-btn @click="dialog = true" color="primary">Ler Contrato</v-btn>
              </v-flex> -->
              <!-- <v-layout align-center fluid>
                <v-flex xs1>
                  <v-checkbox
                    v-model="termo"
                    :rules="[
                      v =>
                        !!v ||
                        'É necessário aceitar o contrato de aluguel para concluir o cadastro!'
                    ]"
                  ></v-checkbox>
                </v-flex>
                <v-flex xs11 class="text-xs-left pl-1 ml-1">
                  Declaro aceitar as condições do <a @click="showResumoContract">Resumo de Termos e Condições</a>, <a @click.prevent="showContract">Contrato de Uso</a>, <a @click.prevent="showEstatuto">Estatuto e Ata de Assembléia</a>.
                </v-flex>
              </v-layout> -->
              <v-flex xs12>
                <!-- <v-btn color="#0d67d0" block round :loading="loading" :disabled="!valid || loading" @click.prevent="validate">ASSINAR</v-btn> -->
                <v-btn
                    color="primary"
                    block
                    round
                    large
                    :loading="loading"
                    :disabled="!valid || loading"
                    @click.prevent="validate"
                    type="submit"
                  >Contratar Energia Limpa</v-btn>
              </v-flex>
            </v-form>
            <!-- <cpnPasso2Vantagens/> -->
            <!-- <v-btn class="btn-assinar" block round color="secondary"  @click.prevent="next">Assinar</v-btn> -->
          </v-layout>
        </v-container>
      </v-flex>
      <v-flex  xl6 lg6 md6 sm12 xs12 class="hidden-sm-and-down">
        <v-layout align-center justify-center row wrap >
          <img src="../../assets/beneficios/passo4.jpg" width="85%"/>
        </v-layout>
      </v-flex>
      <div class="text-xs-center">
        <v-dialog v-model="dialogContract" width="700" scrollable>
          <v-card>
            <v-card-title class="headline grey lighten-2 pa-3" primary-title>Assinatura de Energia Limpa</v-card-title>

            <v-card-text class="pa-3">
              <cpnContratoAluguel :lead="leadContract" />
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions class="ml-auto">
              <v-spacer></v-spacer>
              <v-btn block color="red" @click="dialogContract = false">FECHAR</v-btn>
              <v-btn block color="secondary" @click="acceptContract">ACEITAR</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogResumo" width="500" scrollable>
          <v-card>
            <v-card-title class="headline grey lighten-2 pa-3" primary-title>Resumo</v-card-title>

            <v-card-text class="pa-3">
              <cpnResumoContrato />
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions class="ml-auto">
              <v-spacer></v-spacer>
              <v-btn block color="red" @click="dialogResumo = false">FECHAR</v-btn>
              <v-btn block color="secondary" @click="acceptContract">ACEITAR</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogEstatuto" width="700" height="500" scrollable>
          <v-card>
            <v-card-title class="headline grey lighten-2 pa-3" primary-title>Estatuto da Cooperativa</v-card-title>

            <v-card-text class="pa-3">
              <!-- Estatuto da Cooperativa https://enercred.com.br/wp-content/uploads/2020/05/estatuto-enercred-coop.pdf -->
              <pdf 
                src="/estatuto-enercred-coop.pdf"
                style="width:100%"
              ></pdf>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions class="ml-auto">
              <v-spacer></v-spacer>
              <v-btn block color="red" @click="dialogEstatuto = false">FECHAR</v-btn>
              <v-btn block color="secondary" @click="acceptContract">ACEITAR</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </v-layout>
    <v-snackbar
      :color="color"
      :bottom="bottom"
      :top="top"
      :left="left"
      :right="right"
      v-model="snackbar"
      :timeout="timeout"
      dark
    >
      <v-icon
      color="white"
      class="mr-3"
      >
      mdi-bell-plus
      </v-icon>
      <div v-html="snackMessage"/>
      <v-icon
      size="16"
      @click="snackbar = false"
      >
      mdi-close-circle
      </v-icon>
    </v-snackbar>
  </v-container>
</template>
<script>
import { saveAnalytics, // saveGTM 
} from "@/services/save_analytics"
// import { SET_CONVERSION } from "../../graphql/rdstation"
import { getLead, updateLead } from '../../services/firebase/lead'
import { storeUser, updateReferralBalance } from '../../services/firebase/user'
import { getIP } from '../../services/getIP'
import ContratoAluguel from "../../components/termsOfUse/ContratoAluguel"
import ResumoContrato from "../../components/termsOfUse/ResumoContrato"
import pdf from "vue-pdf"

export default {
  components: {
    cpnContratoAluguel: ContratoAluguel,
    cpnResumoContrato: ResumoContrato,
    pdf
  },
  data() {
    return {
      loading_inicio: true,
      loading: false,
      valid: false,
      isProd: false,
      estadosCivis: ["Solteiro(a)", "Casado(a)", "Separado(a)", "Divorciado(a)", "Viúvo(a)"],
      estadoCivil: "",
      profissao: "",
      profissaoRules: [value => !!value || "Por favor, informe a profissão!"],
      identidade: "",
      identidadeRules: [value => !!value || "Por favor, informe o numero de identidade!"],
      orgao_exp: "",
      orgao_expRules: [value => !!value || "Por favor, informe o orgão expeditor do documento!"],
      date_of_birth: "",
      gender: "",
      regimeDeBens: ["Parcial de bens", "Universal de bens", "Separação total", "Final nos aquestos"],
      comunhaoDeBens: "",
      titular: "",
      payment_type: "",
      termo: "",
      isIdentidade: false,
      isGender: false,
      isProfissao: false,
      isDate_of_birth: false,
      lead: {},
      items: [],
      color: null,
			colors: [
					'purple',
					'info',
					'success',
					'warning',
					'error'
      ],
			top: true,
			bottom: false,
			left: false,
      right: false,
      timeout: 36000,
			snackMessage: '',
      snackbar: false,
      dialogContract: false,
      leadContract: null,
      dialogResumo: false,
      dialogEstatuto: false,
      currentPage: 0,
      pageCount: 0,
    }
  },
  created() {
    this.loading_inicio = true
    this.isProd = process.env.NODE_ENV === "production"
    if(this.$route.query.email) {
      const email = this.$route.query.email
      getLead(email)
        .then(lead => {
          this.lead = lead
          this.gender = lead.data().gender
          this.isGender = lead.data().gender?true:false
          this.profissao = lead.data().profession
          this.isProfissao = lead.data().profession?true:false
          this.date_of_birth = lead.data().date_of_birth
          this.titular = lead.data().titular
          this.isDate_of_birth = lead.data().date_of_birth?true:false
          this.identidade = lead.data().ID_document?lead.data().ID_document.split('-')[0]:''
          this.orgao_exp = lead.data().ID_document?lead.data().ID_document.split('-')[1]:''
          this.estadoCivil = lead.data().marital_status || ''
          this.comunhaoDeBens = lead.data().marital_type || ''
          this.payment_type = lead.data().payment_type || ''
          this.isIdentidade = lead.data().ID_document?true:false
          this.loading = false
          this.loading_inicio = false
        })
        .catch(() => {
          console.error('Email não encontrado: ', email)
          this.loading = false
          this.loading_inicio = false
        })
    } else {
      const lead = JSON.parse(localStorage.getItem("lead"))
      // console.log('lead: ', lead)
      if(lead && lead.email) {
        this.lead = lead
        this.gender = lead.gender
        this.isGender = lead.gender?true:false
        this.profissao = lead.profession
        this.isProfissao = lead.profession?true:false
        this.date_of_birth = lead.date_of_birth
        this.isDate_of_birth = lead.date_of_birth?true:false
        this.identidade = lead.ID_document?lead.ID_document.split('-')[0]:''
        this.orgao_exp = lead.ID_document?lead.ID_document.split('-')[1]:''
        this.titular = lead.titular
        this.isIdentidade = lead.ID_document?true:false
        this.estadoCivil = lead.marital_status || ''
        this.comunhaoDeBens = lead.marital_type || ''
        this.payment_type = lead.payment_type || ''
      }
      this.loading = false
      this.loading_inicio = false
    }
  },
  methods: {
    async validate() {
      if (this.$refs.form.validate() && this.lead && this.lead.name && this.lead.email) {
        const ipinfo = await getIP()
        this.loading = true
        let status = this.payment_type === 'credit_card'?'WAIT_ADD_CREDIT_CARD':'QUALIFICAR'

        let user = {
          ...this.lead,
          titular: this.titular || '',
          password: this.lead.cpf,
          termouso: true,
          gender: this.gender,
          date_of_birth: this.date_of_birth,
          ID_document: this.identidade + '-' + this.orgao_exp,
          group: 'customer',
          profession: this.profissao || 'autonomo',
          marital_status: this.estadoCivil || 'solteiro',
          marital_type: this.comunhaoDeBens || '',
          payment_type: this.payment_type || "credit_card",
          percentage_discount: 10,
          status: status,
          signedcontract_ipinfo: ipinfo.data,
          signedcontract_at: new Date(),
          signedcontract_by: 'onboarding',
          signedcontract_source: 'online',
          signedcontract_partner: 'enercred',
          step: 14,
          available_discounts: [
            {
              method: 'default',
              percentage: 10
            },
            {
              method: 'credit_card',
              percentage: 15
            },
          ],

          
          // referral_reward: process.env.VUE_APP_REFERRAL_VALUE_INDICADO,

        }
        // if(user.promo_code) user.referral_balance = process.env.VUE_APP_REFERRAL_VALUE_INDICADOR
        if(user.installation_numbers){
          user.installation_numbers = user.installation_numbers.map(e => {
            return {
              'text': e, 
              'color': 'purple'
            };
          })
        }
        if(this.payment_type === 'boleto') {
          user.boleto_accepted_by =  "onboarding",
          user.boleto_ipinfo = ipinfo.data
        }

        // this.$store.commit("storeUserState", user)

        await updateLead(this.lead.email, user)
          .then(result => {
            if (result.data) {
              localStorage.setItem("lead", JSON.stringify(user))
            }
          })
          .catch(err => {
            this.loading = false
            console.error("Error Lead: ", err)
          })

        await storeUser(user)
        // storeUser(user)
          .then(async res => {
            // console.log('res: ', JSON.stringify(res))
            if(res.error) {
              if(res.error === 'duplicate') {
                this.snack('Você já possui um cadastro conosco, entre em contato com nosso time para que possamos ajudar você.')
              } else {
                this.snack('Erro ao criar o usuário, por favor, entre em contato com nosso suporte em atendimento@enercred.com.br')
              }
            }
            user.id = res.  id
            user.customer_id = res.id
            user.buyer_id = res.buyer_id
            this.$store.commit("storeUserState", user)
            localStorage.setItem("newUser", JSON.stringify(user))
            // analytics
            if (this.isProd) {
              
              const analytics = {
                eventCategory: "onboard",
                eventAction: "SIGN-CONTRACT",
                eventLabel: "enercred",
                transaction_id: user.email,
                value: user.average_consumption,
                utm_source: this.lead.utm_source,
                utm_medium: this.lead.utm_medium,
                utm_campaign: this.lead.utm_campaign,
                utm_content: this.lead.utm_content,
                utm_term: this.lead.utm_term,
                payment_type: user.payment_type,
                boleto: this.payment_type === 'boleto'?true:false
              }
              if(user.promo_code) {
                analytics.referral_code = {
                  value: this.average_consumption,
                  action: 'REFERRAL_SIGN-CONTRACT',
                  category: 'REFERRAL',
                  indicated: user.email,
                  indicator: user.promo_code
                }
                // console.log('referral_bonus: ', process.env.VUE_APP_REFERRAL_VALUE_INDICADOR)
                if(process.env.VUE_APP_REFERRAL_VALUE_INDICADOR) {
                  // console.log('referral_bonus inside: ', process.env.VUE_APP_REFERRAL_VALUE_INDICADOR)
                  await updateReferralBalance(user.promo_code, process.env.VUE_APP_REFERRAL_VALUE_INDICADOR)
                }
                  
              }
              saveAnalytics(analytics)
              const gtm_data = {
                event: "assinar",
                target: "onboarding_web",
                // action: data.action,
                // "target-properties": data.label,
                value: user.average_consumption,
                // "interaction-type": data.noninteraction,
              }
              // saveGTM(gtm_data)
              this.$gtm.trackEvent(gtm_data);
              // const conversion = {
              //   event_type: "OPPORTUNITY",
              //   event_family: "CDP",
              //   payload: {
              //     email: user.email,
              //     funnel_name: "default"
              //   }
              // }
              // this.setRDConversion(conversion)
            }
            if(user.payment_type === 'boleto' || user.payment_type === 'pix') {
              this.loading = false
              this.$router.push("/assinatura/boas-vindas")
            } else {
              this.loading = false
              this.$router.push("/assinatura/cartao")
            }
            return true
          })
          .catch(error => {
            let msg = 'Erro ao gravar informações. Por favor, contate nosso suporte em (31) 3500-3614 ou whatsApp <a href="https://wa.me/5535998625580?text=' + error + '">(35) 99862-5580</a>.'
            if(error.toString().includes('already')) {
              msg = 'O email já está sendo utilizado! Por favor, contate nosso suporte em (31) 3500-3614 ou whatsApp <a href="https://wa.me/5535998625580?text=' + error + '">(35) 99862-5580</a>.'
            }
            console.error(msg)
            this.snack(msg, 'red', 'top')
            this.loading = false
            return false
          })

        // console.log('user: ', user)
        this.loading = false
      }
    },
    // setRDConversion(conversion) {
    //   return this.$apollo.mutate({
    //     // Query
    //     mutation: SET_CONVERSION,
    //     // Parameters
    //     variables: {
    //       event_type: conversion.event_type,
    //       event_family: conversion.event_family,
    //       payload: conversion.payload
    //     }
    //   })
    // },
    // Quando seleciona estado civil reseta comunhao de bens
    deselecionaRegimeDeBens() {
      this.comunhaoDeBens = undefined
      // this.alertMessage = ""
    },
    async showContract() {
      this.leadContract = {
        ...this.lead,
        gender: this.gender,
        date_of_birth: this.date_of_birth,
        ID_document: this.identidade + '-' + this.orgao_exp,
        profession: this.profissao,
        marital_status: this.estadoCivil || 'solteiro',
        marital_type: this.comunhaoDeBens,
        payment_type: this.payment_type || "pix",
        signedcontract_at: new Date()
      }
      this.dialogContract = true
    },
    async showResumoContract() {
      this.dialogResumo = true
    },
    async showEstatuto() {
      this.dialogEstatuto = true
    },
    acceptContract() {
      this.termo = true
      this.dialogContract = false
      this.dialogResumo = false
      this.dialogEstatuto = false
    },
    snack (message, color, ...args) {
			this.top = false
			this.bottom = false
			this.left = false
			this.right = false

			for (const loc of args) {
					this[loc] = true
			}

			this.color = color
			this.snackMessage = message
			this.snackbar = true
		},
  },
}
</script>

<style scoped>
.fundo {
  background-color: #FFF;
  height: 100vh;
}
.btn-assinar {
  margin: 10px 20px 10px 20px;
  font-weight: 900;
  font-size: 1.1rem;
}
.cabecalho {
  height: 70px;
  vertical-align: middle;
  display: table-cell;
  margin-top: 20px;
}
</style>
